import * as React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import { StaticImage } from "gatsby-plugin-image"
import { Row, Container, Col } from "react-bootstrap"

const MediaArticle = () => (
  <Layout>
    <Seo
      title="Society Interiors - August 2014"
      description="We specialise in F&amp;B, Leisure and Luxury spaces - crafting a complete guest experience through a multidisciplinary approach."
    />

    <section className="awards_article p_x">
      <Container>
        <Row className="no-gutters">
          <Col>
            <StaticImage
              src="../../images/awards/articles/society-interiors_01.jpg"
              alt="Society Interiors Cover - August 2014"
            />
            <br />
            <br />
            <StaticImage
              src="../../images/awards/articles/society-interiors_02.jpg"
              alt="Society Interiors Enchanting Experience - August 2014"
            />
          </Col>
        </Row>
      </Container>
    </section>
  </Layout>
)

export default MediaArticle
